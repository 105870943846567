<script lang="ts">
    import { goto } from '$app/navigation';
    import { getSession } from '$lib/session';
    import { onMount } from 'svelte';

    const session = getSession();

    onMount(() => {
        if ($session.type === 'user') goto('/dashboard');
        else goto('/session/login');
    });
</script>
